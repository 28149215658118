import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Home from '../src/components/Home.js'

const styles = (theme) => ({})

class App extends Component {
  constructor() {
    super()
    this.state = {}
  }
  /* componenetDidMount () {
    window.MyVars = {
      QRCode: require('./components/qrcode-react.js'),
    };
} */

  render() {
    const { classes } = this.props
    return (
     <Home />

    )
  }
}

App.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired
}

export default withStyles(styles)(App)
