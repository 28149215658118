import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import rocket from "../assets/images/rocket.gif";
import bannerIpfs from "../assets/images/algorand-ipfs.jpg";
import bannerS3 from "../assets/images/algorand-s3.jpg";
import myAlgo from "../assets/images/myalgo-blue.png";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Input,
  DialogTitle,
  TextField,
  Switch,
  FormControl,
  FormControlLabel,
  Grid,
  DialogActions,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import algorandLogo from "../assets/images/algorand_full_logo_black.svg";

const styles = (theme) => ({
  algorandImage: {
    width: "128px",
  },
  moreInfoBtn: {
    borderBottomRightRadius: "25px",
    borderBottomLeftRadius: "25px",
    textAlign: "center",
    margin: "auto",
    display: "block",

    borderColor: "rgb(14 177 148)",
    color: "#3c4858",
    "&:hover": {
      backgroundImage: "linear-gradient(to right,#62a7b2  ,#97e392)",
      color: "#000",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  exampleBtn: {
    borderRadius: "20px",
    textAligne: "center",
    margin: "10px",
    display: "inline-block",
    backgroundImage: "linear-gradient(to right, #09af99  , #98e208)",
    borderColor: "rgb(115, 175, 245)",
    color: "#fff",
    "&:hover": {
      backgroundImage: "linear-gradient(to right, #62a7b2  ,#97e392)",
      color: "#000",
    },
  },
  moreInfoBtnSelected: {
    borderTopRightRadius: "25px",
    borderTopLeftRadius: "25px",
    textAlign: "center",
    margin: "auto",
    display: "block",

    borderColor: "rgb(21, 175, 245)",
    color: "#3c4858",
    "&:hover": {
      backgroundImage: "linear-gradient(to right, #62a7b2  ,#97e392)",
      color: "#000",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  mainDiv: {
    position: "relative",
    minHeight: "100vh",
    // weight: "100vw",
    overflow: "auto",
    textAlign: "center",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#726e6e",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#605c5c",
    },
    scrollbarColor: "#726e6e",
    scrollbarWidth: "thin",
  },
  exampleBtnSelected: {
    borderRadius: "20px",
    textAligne: "center",
    margin: "10px",
    display: "inline-block",
    backgroundColor: "#98e208",
    borderColor: "rgb(21, 175, 245)",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#97e392",
      color: "#3c4858",
    },
  },
  closeBtn: {
    position: "absolute",
    right: "16px",
    top: "16px",
    color: "#ff3300",
  },
  dialogTitle: {
    fontSize: "10px",
  },
  resulBtn: {
    backgroundColor: "#c2c1c1",
    borderRadius: "20px",
    textAligne: "center",
    margin: "5px",
    width: 180,
    fontSize: 12,
    display: "inline-block",
    borderColor: "rgb(191, 191, 191)",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c2c1c1",
      color: "#333333",
    },
  },
  footer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down('md')]:{
      maxHeight: 40
    },
    [theme.breakpoints.down('sm')]:{
      maxHeight: 50
    },
    [theme.breakpoints.down('xs')]:{
      maxHeight: 50
    }
  },
  myAlgoBtn:{
    width:'100px'
  }
});

const CustomSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#f2a516",
    },
    "&$checked + $track": {
      backgroundColor: "#f2a516",
    },
  },
  checked: {},
  track: {},
})(Switch);

class Home extends Component {
  constructor() {
    super();
    this.state = {
      showGen: false,
      showAsset: false,
      showTxn: false,
      showTxnOffline: false,
      showPayment: false,
      isDescEnabled: false,
      showNft: false,
      isFileModalOpen: false,
      isMultiFileModalOpen: false,
      isJsonModalOpen: false,
      isArc3ModalOpen: false,
      isMyAlgoModalOpen: false,
      fileName: "",
      fileURL: "",
      fileDescription: "",
      collection: [],
      collectionURL: "",
      collectionName: "",
      collectionDescription: "",
      collectionAuthor: "",
      jsonFileURL: "",
      arc3File: "",
      arc3Name: "",
      arc3Description: "",
      arc3Author: "",
      arc3Unit: "",
      isEditor: false,
      jsonData: {
        sampleKey: "Sample Value",
      },
      showResults: false,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleClickFileBtn = this.handleClickFileBtn.bind(this);
    this.handleClickMultiFileBtn = this.handleClickMultiFileBtn.bind(this);
    this.handleClickArc3Btn = this.handleClickArc3Btn.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleClickJsonBtn = this.handleClickJsonBtn.bind(this);
    this.handleFileTitleChange = this.handleFileTitleChange.bind(this);
    this.handleFileAuthorChange = this.handleFileAuthorChange.bind(this);
    this.handleFileDescriptionChange =
      this.handleFileDescriptionChange.bind(this);
    this.handleUploadCollectionFile =
      this.handleUploadCollectionFile.bind(this);
    this.handleCollectionTitleChange =
      this.handleCollectionTitleChange.bind(this);
    this.handleCollectionDescriptionChange =
      this.handleCollectionDescriptionChange.bind(this);
    this.handleCollectionAuthorChange =
      this.handleCollectionAuthorChange.bind(this);
    this.handleUploadArc3File = this.handleUploadArc3File.bind(this);
    this.handleArc3TitleChange = this.handleArc3TitleChange.bind(this);
    this.handleArc3DescriptionChange =
      this.handleArc3DescriptionChange.bind(this);
    this.handleArc3AuthorChange = this.handleArc3AuthorChange.bind(this);
    this.handleClickSwitch = this.handleClickSwitch.bind(this);
    this.handleJSONChange = this.handleJSONChange.bind(this);
  }

  handleCloseModal() {
    this.setState({
      isFileModalOpen: false,
      isMultiFileModalOpen: false,
      isJsonModalOpen: false,
      isArc3ModalOpen: false,
      isMyAlgoModalOpen: false,
    });
  }
  handleClickFileBtn() {
    this.setState({
      isFileModalOpen: true,
      showGen: true,
      showAsset: false,
      showTxn: false,
      showTxnOffline: false,
      showPayment: false,
      showNft: false,
    });
  }
  handleClickMultiFileBtn() {
    this.setState({
      isMultiFileModalOpen: true,
      showGen: false,
      showAsset: false,
      showTxn: false,
      showTxnOffline: false,
      showPayment: true,
      showNft: false,
    });
  }
  handleClickJsonBtn() {
    this.setState({
      isJsonModalOpen: true,
      showGen: false,
      showAsset: true,
      showTxn: false,
      showTxnOffline: false,
      showPayment: false,
      showNft: false,
    });
  }
  handleClickArc3Btn() {
    this.setState({
      isMyAlgoModalOpen: true,
      showGen: false,
      showNft: true,
      showAsset: false,
      showTxn: false,
      showTxnOffline: false,
      showPayment: false,
    });
  }
  handleUploadFile(event) {
    this.setState({ fileURL: event.target.value });
  }
  handleUploadCollectionFile(event) {
    this.setState({ collection: event.target.value });
  }
  handleCollectionTitleChange(event) {
    this.setState({ collectionName: event.target.value });
  }
  handleCollectionDescriptionChange(event) {
    this.setState({ collectionDescription: event.target.value });
  }
  handleCollectionAuthorChange(event) {
    this.setState({ collectionAuthor: event.target.value });
  }
  handleFileTitleChange(event) {
    this.setState({ fileName: event.target.value });
  }
  handleFileDescriptionChange(event) {
    this.setState({ fileDescription: event.target.value });
  }
  handleFileAuthorChange(event) {
    this.setState({ fileAuthor: event.target.value });
  }
  handleUploadArc3File(event) {
    this.setState({ arc3File: event.target.value });
  }
  handleArc3TitleChange(event) {
    this.setState({ arc3Name: event.target.value });
  }
  handleArc3DescriptionChange(event) {
    this.setState({ arc3Description: event.target.value });
  }
  handleArc3AuthorChange(event) {
    this.setState({ arc3Author: event.target.value });
  }
  handleClickSwitch() {
    this.setState({ isEditor: !this.state.isEditor });
  }
  handleJSONChange(event) {
    this.setState({ Json: { sampleKey: event.target.value } });
  }

  render() {
    const { classes } = this.props;
    const {
      isFileModalOpen,
      isMultiFileModalOpen,
      isArc3ModalOpen,
      isJsonModalOpen,
      fileDescription,
      fileAuthor,
      fileURL,
      fileName,
      collectionURL,
      collectionName,
      collectionDescription,
      collectionAuthor,
      jsonData,
      arc3File,
      arc3Name,
      arc3Description,
      arc3Author,
      isEditor,
      showResults,
      isMyAlgoModalOpen
    } = this.state;
    return (
      <>
        <Dialog
          open={isFileModalOpen}
          onClose={this.handleCloseModal}
          classes={{
            paper: classes.dialogRoot,
          }}
          fullWidth
          maxWidth="sm"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={this.handleCloseModal}
          >
            <Close />
          </IconButton>
          <DialogTitle className={classes.dialogTitle}>
            PIN NFT FILE TO IPFS
          </DialogTitle>
          <DialogContent>
            <Input
              disableUnderline
              type="file"
              style={{
                padding: 10,
                backgroundImage:
                  "linear-gradient(to right, #09af99  , #98e208)",
                marginTop: "10%",
                borderRadius: 10,
              }}
              onChange={this.handleUploadFile}
              fullWidth
            />
            <br />
            <TextField
              required
              label="Name"
              placeholder=""
              type="text"
              margin="normal"
              fullWidth
              className={classes.textField}
              value={
                fileURL !== ""
                  ? fileURL.substring(0, fileURL.length - 4)
                  : fileName
              }
              onChange={this.handleFileTitleChange}
            />
            <br />
            <TextField
              label="Description"
              placeholder="Description"
              type="text"
              margin="normal"
              className={classes.textField}
              value={fileDescription}
              fullWidth
              onChange={this.handleFileDescriptionChange}
            />
            <br />
            <TextField
              label="Author"
              placeholder="Autor name"
              type="text"
              margin="normal"
              className={classes.textField}
              value={fileAuthor}
              fullWidth
              onChange={this.handleFileAuthorChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal}>Ok</Button>
            <Button onClick={this.handleCloseModal}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isMultiFileModalOpen}
          onClose={this.handleCloseModal}
          classes={{
            paper: classes.dialogRoot,
          }}
          fullWidth
          maxWidth="sm"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={this.handleCloseModal}
          >
            <Close />
          </IconButton>
          <DialogTitle className={classes.dialogTitle}>
            PIN NFT COLLECTION TO IPFS
          </DialogTitle>
          <DialogContent>
            <Input
              disableUnderline
              type="file"
              style={{
                padding: 10,
                backgroundImage:
                  "linear-gradient(to right, #09af99  , #98e208)",
                marginTop: "10%",
                borderRadius: 10,
              }}
              onChange={this.handleUploadCollectionFile}
              fullWidth
            />
            <br />
            <TextField
              required
              label="Name"
              placeholder=""
              type="text"
              margin="normal"
              fullWidth
              className={classes.textField}
              value={
                collectionURL !== ""
                  ? collectionURL.substring(0, collectionURL.length - 4)
                  : collectionName
              }
              onChange={this.handleCollectionTitleChange}
            />
            <br />
            <TextField
              label="Description"
              placeholder="Description"
              type="text"
              margin="normal"
              fullWidth
              className={classes.textField}
              value={collectionDescription}
              onChange={this.handleCollectionDescriptionChange}
            />
            <br />
            <TextField
              label="Author"
              placeholder="Autor name"
              type="text"
              margin="normal"
              className={classes.textField}
              value={collectionAuthor}
              fullWidth
              onChange={this.handleCollectionAuthorChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal}>Ok</Button>
            <Button onClick={this.handleCloseModal}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isJsonModalOpen}
          onClose={this.handleCloseModal}
          classes={{
            paper: classes.dialogRoot,
          }}
          fullWidth
          maxWidth="sm"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={this.handleCloseModal}
          >
            <Close />
          </IconButton>
          <DialogTitle className={classes.dialogTitle}>
            PIN NFT JSON TO IPFS
          </DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item style={{ paddingRight: 14 }}>
                      File
                    </Grid>
                    <Grid item>
                      <CustomSwitch
                        checked={isEditor}
                        onChange={this.handleClickSwitch}
                        value={isEditor}
                      />
                    </Grid>
                    <Grid item>Editor</Grid>
                  </Grid>
                }
                // style={{ display: "inline-block", marginTop: "10px" }}
                labelPlacement="start"
              />
            </FormControl>
            <br />
            {isEditor ? (
              <TextField
                multiline
                maxRows={10}
                defaultValue={JSON.stringify(jsonData)}
                onChange={this.handleJSONChange}
                variant="filled"
                fullWidth
              />
            ) : (
              <Input
                disableUnderline
                type="file"
                fullWidth
                style={{
                  padding: 10,
                  backgroundImage:
                    "linear-gradient(to right, #09af99  , #98e208)",
                  marginTop: "10%",
                  borderRadius: 10,
                }}
                onChange={this.handleUploadJSONFile}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal}>Ok</Button>
            <Button onClick={this.handleCloseModal}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isArc3ModalOpen}
          onClose={this.handleCloseModal}
          classes={{
            paper: classes.dialogRoot,
          }}
          fullWidth
          maxWidth="sm"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={this.handleCloseModal}
          >
            <Close />
          </IconButton>
          <DialogTitle className={classes.dialogTitle}>
            CREATE ARC3 NFT
          </DialogTitle>
          <DialogContent>
            <Input
              disableUnderline
              type="file"
              style={{
                padding: 10,
                backgroundImage:
                  "linear-gradient(to right, #09af99  , #98e208)",
                marginTop: "10%",
                borderRadius: 10,
              }}
              onChange={this.handleUploadArc3File}
              fullWidth
            />
            <br />
            <TextField
              required
              label="Name"
              placeholder=""
              type="text"
              margin="normal"
              className={classes.textField}
              value={
                arc3File !== ""
                  ? arc3File.substring(0, arc3File.length - 4)
                  : arc3Name
              }
              onChange={this.handleArc3TitleChange}
              fullWidth
            />
            <br />
            <TextField
              label="Description"
              placeholder="Description"
              type="text"
              margin="normal"
              className={classes.textField}
              value={arc3Description}
              onChange={this.handleArc3DescriptionChange}
              fullWidth
            />
            <br />
            <TextField
              label="Author"
              placeholder="Author"
              type="text"
              margin="normal"
              className={classes.textField}
              value={arc3Author}
              onChange={this.handleArc3AuthorChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal}>Ok</Button>
            <Button onClick={this.handleCloseModal}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isMyAlgoModalOpen}
          onClose={this.handleCloseModal}
          classes={{
            paper: classes.dialogRoot,
          }}
          fullWidth
          maxWidth="sm"
        >
          <IconButton
            className={classes.closeBtn}
            onClick={this.handleCloseModal}
          >
            <Close />
          </IconButton>
          <DialogTitle className={classes.dialogTitle}>
            
          </DialogTitle>
          <DialogContent style={{textAlign: 'center'}}>
            <Button onClick={this.handleClickArc3Btn} >
              <img src={myAlgo} className={classes.myAlgoBtn} />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <div className={classes.mainDiv}>
          <div>
          <img
            style={{
              width: "512px",
              borderBottomRightRadius: "100px",
              borderBottomLeftRadius: "100px",
              margin: "auto",
              display: "block",
            }}
            src={bannerIpfs}
            width="50%"
            alt="Algorand IPFS Gateway API"
          />

          <h4
            style={{
              display: this.state.isDescEnabled ? "block" : "none",
              textJustify: "inter-word",
              marginLeft: '10%',
              marginRight: '10%'
            }}
            className="Home-desc"
          >
            Algorand IPFS is a React SPA implementation of unified client for
            <a
              target="_self"
              href="https://api.algorand-ipfs.com"
            >{` Algorand IPFS Gateway `}</a>{" "}
            {/* and
          <a target="_blank" href="https://github.com/emg110/algorand-s3"> {` Algorand S3 Gateway `} </a> */}
            with simplicity of a POC and effectiveness of a production system in
            mind, using
            <a
              id="myalgo-link"
              target="_blank"
              href="https://wallet.myalgo.com/"
            >
              {` MyAlgo `}
            </a>
            wallet on{" "}
            <a
              id="algoexplorer-link"
              target="_blank"
              href="https://algoexplorer.io/"
            >
              {` TESTNET `}
            </a>
          </h4>

          <Button
            id="desc-button"
            variant="outlined"
            onClick={() =>
              this.setState({
                isDescEnabled: !this.state.isDescEnabled,
              })
            }
            className={
              this.state.isDescEnabled
                ? classes.moreInfoBtnSelected
                : classes.moreInfoBtn
            }
          >
            {this.state.isDescEnabled ? "Less info" : "More info"}
            <img
              style={{
                transform: "rotate(90deg)",
                display: "inline-block",
                padding: "0",
                verticalAlign: "middle",
              }}
              width="64px"
              src={rocket}
            />
          </Button>
          <br />
          <h4
            style={{ display: "inline-block", verticalAlign: "middle" }}
            className="Home-name"
          >
            Algorand IPFS
          </h4>
          <br />
          <Button
            id="pin-file-button"
            variant="outlined"
            onClick={this.handleClickFileBtn}
            className={
              this.state.showGen
                ? classes.exampleBtnSelected
                : classes.exampleBtn
            }
          >
            PIN NFT FILE TO IPFS
          </Button>
          <Button
            id="pin-folder-button"
            variant="outlined"
            onClick={this.handleClickMultiFileBtn}
            className={
              this.state.showPayment
                ? classes.exampleBtnSelected
                : classes.exampleBtn
            }
          >
            PIN NFT COLLECTION TO IPFS
          </Button>
          <Button
            id="pin-jsonData-button"
            variant="outlined"
            onClick={this.handleClickJsonBtn}
            className={
              this.state.showAsset
                ? classes.exampleBtnSelected
                : classes.exampleBtn
            }
          >
            PIN NFT JSON TO IPFS
          </Button>
          <Button
            id="gen-nft-button"
            variant="outlined"
            onClick={this.handleClickArc3Btn}
            className={
              this.state.showAsset
                ? classes.exampleBtnSelected
                : classes.exampleBtn
            }
          >
            Create ARC3 NFT
          </Button>
          <br />
          {showResults && (
            <>
              <a
                type="button"
                target="_blank"
                href="#"
                className={classes.resulBtn}
              >
                META DATA JSON IPFS URL
              </a>
              <a
                target="_blank"
                href="#"
                variant="outlined"
                // onClick={this.handleClickArc3Btn}
                className={classes.resulBtn}
              >
                DIGITAL ASSET IPFS URL
              </a>
              <a
                variant="outlined"
                target="_blank"
                href="#"
                className={classes.resulBtn}
              >
                ALGORAND ASSET URL
              </a>
            </>
          )}

          {/*   {this.state.showGen && <QrCodeGenerator />}
        {this.state.showAsset && <AssetExample />}
        {this.state.showPayment && <PaymentTransactionExample />} */}
          <div style={{ textAlign: "center", zIndex: 5 }}>
            <p style={{ fontSize: "14px" }}>
              <span>
                &copy; {1900 + new Date().getYear()}{" "}
                <a href="https://github.com/emg110/" target="_self">
                  Made with love by @emg110 & @sheghzo
                </a>
                <br />A unified Storage Service Provider made for & powered by
              </span>
            </p>

            <img src={algorandLogo} className={classes.algorandImage} />
            <br />
            <span>
              Built to be used by
              <a href="https://diskoin.com" target="_blank">
                {` DISKOIN `}
              </a>
              &
              <a href="https://alpos.emg110.com" target="_blank">
                {` ALPOS `}
              </a>
              <br /> & We hope soon, all Algorand community
              <br />
              <br />
              <br />
            </span>
          </div>
          </div>
          <br />
          <div className={classes.footer}>
            Coming soon
            <img
              style={{
                width: "256px",
                borderTopRightRadius: "50px",
                borderTopLeftRadius: "50px",
                margin: "auto",
                display: "block",
                opacity: "0.5",
              }}
              src={bannerS3}
              width="50%"
              alt="Algorand IPFS Gateway API"
            />
          </div>
        </div>
      </>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Home);
